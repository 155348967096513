import React, { useEffect, useState } from 'react';
import {  CircularProgress, Alert } from '@mui/material';
import EnhancedTable from './FaceOffTable';
import axios from 'axios';

const defaultHeadCells = [
  { id: 'ranking', label: 'Ranking', numeric: true },
  { id: 'tier', label: 'Tier', numeric: false },
  { id: 'leaderboard_name', label: 'Leaderboard', numeric: false, page: 'leaderboard?leaderboard_id=', link_data: 'leaderboard_id' },
  { id: 'faceoff', label: 'Rank Now', numeric:false, criterion: 'criterion', competitor:'corelation_id'}
];

const CompetitorRankingTable = ({leaderboardId, competitorId, ranking, seeded,headCells=defaultHeadCells, defaultSortField="ranking", defaultSortOrder="asc"}) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async (competitorId,ranking,seeded,leaderboardId) => {
    try {
      let params = [];
      if (ranking) {
        params.push(`ranking=${ranking}`);
      }
      if (leaderboardId) {
        params.push(`leaderboard_id=${leaderboardId}`);
      }
      if(seeded != null) {
        params.push(`seeded=${seeded}`);
      }
      if (competitorId) {
        params.push(`competitor_id=${competitorId}`);       
      }
      let qryParams = params.join("&");
      const url = `${process.env.REACT_APP_SERVER_URL}/GetLeaderboardRankings?${qryParams}`;
      console.log("URL ",  url);
      const response = await axios.get(url);
      setRows(response.data); // Assuming the API returns the array of rows directly
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch data');
      setLoading(false);
    }
  };

  // Fetch data from the API
  useEffect(() => {
    fetchData(competitorId, ranking, seeded,leaderboardId);
  }, [competitorId, ranking, seeded, leaderboardId]);
  

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
      <EnhancedTable rows={rows} headCells={headCells} defaultSortField={defaultSortField} defaultSortOrder={defaultSortOrder} />
  );
};

export default CompetitorRankingTable;

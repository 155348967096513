import React, { useEffect, useState } from 'react';
import { CircularProgress, Alert } from '@mui/material';
import EnhancedTable from './FaceOffTable';
import axios from 'axios';

const headCells = [
  { id: 'large_image_url', label: 'Image', numeric: false, isImage:true }, // Image field
  { id: 'leaderboard_name', label: 'Leaderboard', numeric: false, page: 'leaderboard?leaderboard_id=', link_data: 'leaderboard_id' },
  { id: 'name', label: 'Game', numeric: false, page: 'competitor/', link_data: 'corelation_id' },
  { id: 'relevancy_metric', label: 'Elo Rating', numeric: true },
  { id: 'tier', label: 'Tier', numeric: false },
  { id: 'faceoff', label: 'Rank Now', numeric:false, criterion: 'criterion', competitor:'corelation_id'}
];

const TopCompetitorsTable = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/GetBestOfCompetitors`);
        setRows(response.data); // Assuming the API returns the array of rows directly
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
      <EnhancedTable rows={rows} headCells={headCells} defaultSortField="ranking" defaultSortOrder="desc" />
  );
};

export default TopCompetitorsTable;

import React, { useEffect, useState } from 'react';
import { useUser } from '../UserContext';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import CriterionCard from '../components/CriterionCard';
import TierList from '../components/TierList';
import FaceOffImageList from '../components/FaceOffImageList';
import AITester from '../components/AITester';
import axios from 'axios';
import { CircularProgress, Container, Alert, Card, Typography, Tabs, Tab, Box, Button } from '@mui/material';
import CompetitorRankingTable from '../components/CompetitorRankingTable';

const LeaderboardPage = () => {
  const [criterionData, setCriterionData] = useState(null); // State for storing criterion data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(''); // Error state
  const [activeTab, setActiveTab] = useState(0); // Default tab is 1 (FaceOffImageList)
  const [leaderboardId, setLeaderboardId] = useState(null); // Default tab is 1 (FaceOffImageList)
  const { user } = useUser();

    // Helper function to parse query parameters
    const useQuery = () => {
      return new URLSearchParams(useLocation().search);
    };
  
    const query = useQuery();
    const criterion_name = query.get('criterion_name');
    const leaderboard_id = query.get('leaderboard_id');


  useEffect(() => {
    // Fetch criterion data when component mounts
    const fetchLeaderboardDetails = async () => {
      try {
        let queryParam = '';
        if (leaderboard_id) {
          queryParam = `?leaderboard_id=${leaderboard_id}`;
          setLeaderboardId(leaderboard_id);
        } else if (criterion_name) {
          queryParam = `?criterion_name=${criterion_name}`;
        }
        let url = `${process.env.REACT_APP_SERVER_URL}/GetLeaderboard${queryParam}`;
        console.log("url",url);
        const response = await axios.get(url); 
        console.log(response.data);
        setCriterionData(response.data);
        setLeaderboardId(response.data.corelation_id);

        document.title = `Leaderboard - Best ${response.data.criterion_id}`;
      } catch (err) {
        setError('Error fetching leaderboard data.');
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboardDetails();
  }, [criterion_name, leaderboard_id]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Update active tab when user clicks on a tab
  };

  const leaderboardHeadCells = [
    { id: 'large_image_url', label: 'Image', numeric: false, isImage:true }, // Image field
    { id: 'ranking', label: 'Ranking', numeric: true },
    { id: 'tier', label: 'Tier', numeric: false },
    { id: 'name', label: 'Game', numeric: false, page: 'competitor/', link_data: 'corelation_id' },
    { id: 'faceoff', label: 'Rank Now', numeric:false, criterion: 'criterion', competitor:'corelation_id'}
  ];

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <div>
      <Container>
        {criterionData ? (
          <CriterionCard
            title={criterionData.name}
            name={criterionData.criterion_name}
            description={criterionData.leaderboard_description}
            avatar_url={criterionData.large_image_url}
            criterionStyles={{
              emoji: criterionData.criterion_emoji || '',
              transition_1_color: criterionData.transition_color_1 || '#4a4a4a', // Example colors
              transition_2_color: criterionData.transition_color_2 || '#141414',
              font_color: criterionData.font_color || '#FFFFFF',
            }}
            tooltipText="This is a tooltip for the leaderboard"
          />
        ) : (
          <Alert severity="warning">No leaderboard data available for this ID.</Alert>
        )}

        <Box sx={{ width: '100%', mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            component={RouterLink}
            to={`/faceoff?criterion_name=${criterionData.criterion_name}`}
            sx={{ mb: 2 }}
          >
            Rank Games
          </Button>
        </Box>

        <Box sx={{ width: '100%', mt: 4 }}>
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange} 
            variant="scrollable"  // Enables horizontal scrolling on small screens
            scrollButtons="auto"  // Show scroll buttons when needed
            allowScrollButtonsMobile  // Allows scroll buttons to appear on mobile
            sx={{
              '.MuiTab-root': { minWidth: 120 }, // Ensures each tab has a minimum width
              display: { xs: 'flex', md: 'block' } // Stacks tabs in a row on small screens
            }}
            centered={false} // Un-center tabs to fit more naturally on mobile
          >
            <Tab label="Tier List" />
            <Tab label="Leaderboard" />
            <Tab label="Your Unseeded Games" />
            {user && user.accountType === 'admin' && (
              <Tab label="AI Tester" />
            )}
          </Tabs>

          <Box sx={{ mt: 2 }}>
            {activeTab === 0 && (
              <Card sx={{ mt: 4, p: { xs: 1, md: 2 } }}>  {/* Smaller padding on mobile */}
                <Typography variant="h5" component="div">  {/* Smaller heading on mobile */}
                  Tier List
                </Typography>
                <TierList leaderboardId={leaderboardId} />
              </Card>
            )}

            {activeTab === 1 && (
              <Card sx={{ mt: 4, p: { xs: 1, md: 2 } }}>
                <Typography variant="h5" component="div">
                  Leaderboard
                </Typography>
                <CompetitorRankingTable headCells={leaderboardHeadCells} leaderboardId={leaderboardId} seeded={1} defaultSortOrder="asc" />
              </Card>
            )}

            {activeTab === 2 && (
              <Card sx={{ mt: 4, p: { xs: 1, md: 2 } }}>
                <Typography variant="h5" component="div">
                  Your Unseeded Games
                </Typography>
                <FaceOffImageList dataSource={`${process.env.REACT_APP_SERVER_URL}/GetMyUnseededCompetitorsForLeaderboard/${leaderboardId}`} showSearch={false} metricDescriptor='elo' />
              </Card>
            )}

            {activeTab === 3 && (
              <Card sx={{ mt: 4, p: { xs: 1, md: 2 } }}>
                <AITester criterion={criterionData.criterion_name} competitorDescriptor='Video Game' criterionDescriptor='Category' />
              </Card>
            )}
          </Box>
        </Box>

      </Container>
    </div>
  );
};

export default LeaderboardPage;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AITester from '../components/AITester';
import FaceOffImageList from '../components/FaceOffImageList';
import CriterionCard from '../components/CriterionCard';

import axios from 'axios';
import { CircularProgress, Container, Alert, Card, Typography, Tabs, Tab, Box } from '@mui/material';
const REACT_APP_COMPETITOR_DESCRIPTION = process.env.REACT_APP_COMPETITOR_DESCRIPTION;

const CriterionPage = () => {
  const { criterionId } = useParams(); // Get criterionId from the URL
  const [criterionData, setCriterionData] = useState(null); // State for storing criterion data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(''); // Error state
  const [activeTab, setActiveTab] = useState(0); // Default tab is 1 (FaceOffImageList)



  useEffect(() => {
    // Fetch criterion data when component mounts
    const fetchCriterion = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/GetCriterion/${criterionId}`); // Example API call
        console.log(response.data)
        setCriterionData(response.data);
      } catch (err) {
        setError('Error fetching criterion data.');
      } finally {
        setLoading(false);
      }
    };

    fetchCriterion();
  }, [criterionId]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Update active tab when user clicks on a tab
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <div>
      <Container>
        {criterionData ? (
          <CriterionCard
            name={criterionData.Name}
            description={criterionData.description}
            avatar_url={criterionData.avatar_url}
            criterionStyles={{
              emoji: criterionData.emoji || "",
              transition_1_color: criterionData.transition_color_1 || "#4a4a4a", // Example colors
              transition_2_color: criterionData.transition_color_2 || "#141414",
              font_color: criterionData.font_color || "#FFFFFF",
            }}
            tooltipText="This is a tooltip for the criterion"
          />
        ) : (
          <Alert severity="warning">No criterion data available for this ID.</Alert>
        )}

        {/* Tabs for AITester and FaceOffImageList */}
        <Box sx={{ width: '100%', mt: 4 }}>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab label={`My Related ${REACT_APP_COMPETITOR_DESCRIPTION}` || 'My Related Competitors'} />
           
            <Tab label="AI Tester" />

          </Tabs>

          <Box sx={{ mt: 2 }}>
            {/* FaceOffImageList Tab (default) */}
            {activeTab === 0 && (
              <Card sx={{ mt: 4, p: 2 }}>
                <Typography variant="h4" component="div">
                  My Related {REACT_APP_COMPETITOR_DESCRIPTION || 'Steam Games'}
                </Typography>
                <FaceOffImageList dataSource={`/GetCompetitorForCriterion/${criterionId}`} />
              </Card>
            )}

            {/* AITester Tab */}
            {activeTab === 1 && (
              <Card>
                <AITester criterion={criterionId} competitorDescriptor='Video Game' criterionDescriptor='Genre' />
              </Card>
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default CriterionPage;

import React, { useEffect, useState } from 'react';
import {  CircularProgress, Alert } from '@mui/material';
import EnhancedTable from './FaceOffTable';
import axios from 'axios';

const headCells = [
  { id: 'large_image_url', label: 'Profile Image', numeric: false, isImage:true }, // Image field
  { id: 'name', label: 'Game', numeric: false, page: 'competitor/', link_data: 'competitor_id' },
  { id: 'leaderboard_name', label: 'Leaderboard Name', numeric: false, page: 'leaderboard?leaderboard_id=', link_data: 'leaderboard' },
  { id: 'ranked_count', label: 'Ranked Count', numeric: true },
  { id: 'faceoff', label: 'Rank Now', numeric:false, criterion: 'criterion', competitor:'competitor_id'}
];

const UnseededCompetitorsTable = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/GetMyUnseededCompetitors`,{withCredentials:true});
        setRows(response.data); // Assuming the API returns the array of rows directly
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
      <EnhancedTable rows={rows} headCells={headCells} defaultSortField="ranked_count" defaultSortOrder="desc" />
  );
};

export default UnseededCompetitorsTable;

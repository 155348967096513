import FaceOffImageList from '../components/FaceOffImageList';
import { Card, Container, Typography,Tabs, Tab } from '@mui/material'; // Import CircularProgress for loading indicator and Alert for error handling
import {React, useState} from 'react';
import FaceOffDataTable from '../components/FaceOffDataTable';


const LeaderbooardsPage = () => {

    document.title = "Leaderboards";

    const headCells = [
        { id: 'criterion_emoji', label: '...', numeric: false},
        { id: 'name', label: 'Game', numeric: false, page: 'leaderboard?leaderboard_id=', link_data: 'corelation_id' },
        { id: 'relevancy_metric', label: '# Games Ranked', numeric: true},
      ];

      const [activeTab, setActiveTab] = useState(0); // Default tab is 1 (FaceOffImageList)
      const handleTabChange = (event, newValue) => {
          setActiveTab(newValue); // Update active tab when user clicks on a tab
        };

    return (
        <Container>
            <Card sx={{ width: "100%", pt:2,pb:2}}>
                <Typography variant="h4" component="div">
                    Leaderboards
                </Typography>
                <Typography variant="body2" component="div">
                    Find the best {process.env.REACT_APP_COMPETITOR_DESCRIPTION || 'Competitors'} for each {process.env.REACT_APP_CRITERION_DESCRIPTOR || "Criterion"}.
                </Typography>
            </Card>

            <Tabs value={activeTab} onChange={handleTabChange} centered>
                <Tab label="Image List" />
                <Tab label="Table" />
            </Tabs>

            {activeTab === 0 && (
                <Card sx={{mt:2, width: "100%"}} >
                    <FaceOffImageList dataSource={`${process.env.REACT_APP_SERVER_URL}/GetLeadboards`} linkTo="leaderboard?leaderboard_id=" itemDescriptor="leaderboard" metricDescriptor="games ranked"/>
                </Card>
            )}

            {activeTab === 1 && (
                <Card>
                <FaceOffDataTable
                    endpoint={`${process.env.REACT_APP_SERVER_URL}/GetLeadboards`}
                    headCells={headCells}
                    defaultSortField={"relevancy_metric"}
                    defaultSortOrder={"desc"}
                />
                </Card>
            )}

        </Container>
    );
};

export default LeaderbooardsPage;

import React from 'react';
import TierList from '../components/TierList';

import { Typography } from '@mui/material';

const TierListPage = () => {
    return (
        <div>          
            <Typography variant="h4" component="h1">
            TierList Aesthetic Example
            </Typography>
            <TierList />
            </div>
    )
};

export default TierListPage;
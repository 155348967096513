import React, { useEffect, useState } from 'react';
import { CircularProgress, Alert, Container, Box } from '@mui/material';
import EnhancedTable from './FaceOffTable';
import Login from '../pages/Login';
import axios from 'axios';


const FaceOffDataTable = ({ endpoint, headCells, defaultSortField, defaultSortOrder }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [unauthorized, setUnauthorized] = useState(false); // Add unauthorized state
  const [success, setSuccess] = useState(false); // Add success state

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(endpoint, { withCredentials: true });
        setRows(response.data); // Assuming the API returns the array of rows directly
        console.log(response.data);
        setLoading(false);
        setSuccess(true);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          setUnauthorized(true); // Set unauthorized if a 401 error occurs
        } else {
          console.error('Error fetching data:', err);
          setError('Server error'); // General error message for non-401 errors
        }
        setSuccess(false); // Set success to false if there was an error
      } finally {
        setLoading(false); // Set loading to false after the request is done
      }
    };

    fetchData();
  }, [endpoint]);

  // Conditional props object for EnhancedTable
  const tableProps = {
    rows,
    headCells,
    ...(defaultSortField && { defaultSortField }), // Only include if defined
    ...(defaultSortOrder && { defaultSortOrder })  // Only include if defined
  };

  return (
    <Container>
      {loading ? (
        // Show loading indicator while loading is true
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : success ? (
        // Once loading is done and data is fetched successfully, show the data
        <EnhancedTable {...tableProps} />
      ) : unauthorized ? (
        // Show login component if a 401 Unauthorized error occurs
        <Login msg="You must sign in to view the data." />
      ) : error && (
        // Show info message when no data is found
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
    </Container>
  );
};

export default FaceOffDataTable;

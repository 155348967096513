import { useEffect } from 'react';
import axios from 'axios';
import { CircularProgress, Typography, Container } from '@mui/material';

function LogoutPage() {

    useEffect(() => {
        handleLogout();
    }, []);

    const handleLogout = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/logout`, { withCredentials: true });
            console.log("Logout response:", response);

            if (response.data.success) {
                // Redirect the client based on the server's response
                window.location.href = response.data.redirectUrl || '/';
            }
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <Container style={{ textAlign: 'center', marginTop: '20px' }}>
            <Typography variant="h5" gutterBottom>
                Logging you out...
            </Typography>
            <CircularProgress />
        </Container>
    );
}

export default LogoutPage;

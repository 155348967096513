import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// Context and Components
import { UserProvider } from './UserContext';
import AppBar from './components/MainAppBar';


// Pages


// Material UI Components
import { Paper} from '@mui/material';
import { styled } from '@mui/material/styles';

import routes from './routes'; // Use require to import CommonJS module
import * as Pages from './pages'; // Import all exports from pages/index.jsx


// Styled component
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#eee',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    align: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));


// Navigation links
const pages = [
    { title: 'Home', url: '/' },
    { title: 'Leaderboards', url: '/Leaderboards' },
    { title: 'Rank Relevant Games', url:'/faceoff?mode=personalised'},
    { title: 'Rank Random', url:'/faceoff?mode=random'},
    {title: 'About',url:'about'}
];

function App() {

    document.title = "WhichIsThe.BEST";
    return (

        <div>
            <UserProvider>
            <div>                    
                <nav>
                    <AppBar pages={pages} />
                </nav>
            </div>
            <div className="App" >
                <Item>
                    <Router>
                    {/* Use Suspense to handle lazy-loaded components */}
                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                        {routes.map((route, index) => {
                            const Component = Pages[route.element]; // Get component by name from Pages
                            if (!Component) {
                            console.error(`Component "${route.element}" not found for path "${route.path}"`);
                            return null;
                            }
                            return <Route key={index} path={route.path} element={<Component />} />;
                        })}
                        </Routes>
                    </Suspense>
                    </Router>
                </Item>
            </div>
            </UserProvider>
        </div>


    );
}

export default App;

import {React, useState} from 'react';
import FaceOffPage from './FaceOffPage';

import { Typography,Container, Card, CardMedia,Box, Tabs, Tab } from '@mui/material';
import TopCompetitorsTable from '../components/TopCompetitorsTable';


const HomePage = () => {
    const [activeTab, setActiveTab] = useState(0); // Default tab is 1 (FaceOffImageList)
const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Update active tab when user clicks on a tab
  };

    return (
        <Container>
            <Card sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}> {/* Align items vertically in the center */}
                <CardMedia
                component="img"
                sx={{ 
                    width: "10%",
                    height: "auto",
                    borderRadius: '16px', // Rounded corners
                    border: '2px solid #ddd', // Optional: Add border for a framed look
                }}
                image={"/images/whichisthebest.png"} // Use placeholder if avatar_url is missing
                alt="WhichIsThe.Best Image"
                />
                
                <Box sx={{ ml: 2, flex: 1, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography variant="h4" component="div" gutterBottom>
                    WhichIsThe.BEST
                </Typography>
                <Typography variant="body2" component="div" gutterBottom>
                    The site where you get to decide which is the best {process.env.REACT_APP_COMPETITOR_DESCRIPTION} for each {process.env.REACT_APP_CRITERION_DESCRIPTOR} by making one simple decsion - which is the best of the two things? 
                    
                </Typography>
                <Typography variant="body2" component="div" gutterBottom> 
                    Sign in and begin ranking!
                </Typography>
                </Box>
            </Box>
            </Card>

            <Tabs value={activeTab} onChange={handleTabChange} centered>
                <Tab label="WHICH IS BEST" />
                <Tab label="WHICH IS BETTER" />
            </Tabs>

            <Box sx={{ mt: 2 }}>
            {activeTab === 0 && (
                <Card sx={{mt:3, p:2}}>
                    <Typography variant="h4" component="div" gutterBottom sx={{ textAlign: { xs: 'center'} }}>
                            Best {process.env.REACT_APP_COMPETITOR_DESCRIPTION}
                    </Typography>
                    <TopCompetitorsTable/>
                </Card>
            )}

                {activeTab === 1 && (
                    <Card sx={{mt:3, p:2}}>
                        <FaceOffPage/>
                    </Card>
                )}
           

            </Box>

        </Container>
    )
};

export default HomePage;
import { Container, Typography, Card, Box } from '@mui/material';

const AboutPage = () => {
    return (
        <Container>
            <Card sx={{ p: 3, mb: 2 }}>
                <Typography variant="h4" gutterBottom>About</Typography>
                <Typography variant="body1" gutterBottom>
                    What is this site all about?
                </Typography>
            </Card>

            <Card sx={{ p: 3, mb: 2 }}>
            <Typography variant="h5" gutterBottom sx={{ textAlign: 'left' }}>
                    Ranking system
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ textAlign: 'left' }}>
                    WhichIsThe.BEST uses the bespoke FaceOff ranking system, which seeks to address the shortcomings of legacy ranking systems:
                </Typography>
                <Box component="ul" sx={{ textAlign: 'left' }}>
                    <li>
                        <Typography variant="body1" sx={{ textAlign: 'left' }}>
                            Ranking is often arbitrary and subject to bias. On this site, you rank things against each other and the algorithm will decide the game’s score.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" sx={{ textAlign: 'left' }}>
                            Things change quickly, what is great one day can be terrible the next. Ranking on this site is fluid and can change often to reflect the changing world we live in.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" sx={{ textAlign: 'left' }}>
                            Comparing one thing against another can be like comparing apples and oranges. In the FaceOff ranking system, everything is categorized, so you only rate apples to apples.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" sx={{ textAlign: 'left' }}>
                            People often only rate things when they are happy or upset. This system aims to make rating fun.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" sx={{ textAlign: 'left' }}>
                            Game tag data comes from Steam and is weighted by community votes. They community often makes strange decisions around these.
                        </Typography>
                    </li>
                </Box>
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'left' }}>
                    How to rank games
                </Typography>
                <Typography variant="body1" sx={{ textAlign: 'left' }}>
                    You must login to rank games, and you will only be able to rank games in your Steam Library. Your privacy setting must allow for your games to be shared publicly for these to be imported into the system. For improved results, allow your playtime to be shared which will give better matches based on your playtime. You can always change your privacy settings once your games are synced, noting this may take a few minutes.
                </Typography>
                <Typography variant="body1" sx={{ textAlign: 'left' }}>
                    There are 4 ways to rank games:
                </Typography>
                <Box component="ul" sx={{ textAlign: 'left' }}>
                    <li>
                        <Typography variant="body1" sx={{ textAlign: 'left' }}>
                            Navigate to 'My Games'. Select a game, then click on a tag. This will start ranking the game against all others that are relevant.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" sx={{ textAlign: 'left' }}>
                            Navigate to 'Leaderboards' and find a leaderboard. Select 'Rank Games' and you will be able to rate all relevant games you own for that category.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" sx={{ textAlign: 'left' }}>
                            'Rank Relevant Games' will serve you the most relevant categories of games you have played the most.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" sx={{ textAlign: 'left' }}>
                            'Rank Random' will pick games and categories at random.
                        </Typography>
                    </li>
                </Box>

                <Typography variant="h6" color="warning" gutterBottom sx={{ textAlign: 'left' }}>
                    This site is in early BETA testing. Expect bugs. Database could be wiped at any time without notice!
                </Typography>
            </Card>

        </Container>
    );
};

export default AboutPage;

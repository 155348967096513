import {React, useState} from 'react';
import { useUser } from '../UserContext';
import {Container,  Card, Typography, Tabs, Tab, Box } from '@mui/material';
import UnseededCompetitorsTable from '../components/UnseededCompetitorsTable'
import ProfileCard from '../components/ProfileCard';
import FaceOffDataTable from '../components/FaceOffDataTable';
import Login from './Login';

const Profile = () => {

  const headCells = [
    { id: 'external_tiny_avatar_URL', label: 'Avatar', numeric: false, isImage:true }, // Image field
    { id: 'display_name', label: 'Username', numeric: false },
    { id: 'seededRate', label: '% Games Seeded', numeric: true },
    { id: 'rankingCount', label: '# Games Compared', numeric: true },
    { id: 'score', label: 'Total Score', numeric: true },

  ];

    const { user } = useUser();
    const [activeTab, setActiveTab] = useState(0); // Default tab is 1 (FaceOffImageList)
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue); // Update active tab when user clicks on a tab
      };

    if (!user) {
        return (
            <Login/>
        );
    }

    return (
        <Container>
            <ProfileCard user={user}></ProfileCard>

            <Box sx={{ width: '100%', mt: 4 }}>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab label="Almost Seeded Games" />
            <Tab label="User Leaderboard" />
          </Tabs>

          <Box sx={{ mt: 2 }}>
            {activeTab === 0 && (
              <Card sx={{ mt: 4, p: 2 }}>
                <Typography variant="h4" component="div">
                Almost Seeded Games
                </Typography>
                <Typography variant="body2" component="div">
                Games are only included on leaderboards once they have been ranked a number of times.
                </Typography>
                <UnseededCompetitorsTable rows={{}}></UnseededCompetitorsTable>
              </Card>
            )}
            {activeTab === 1 && (
              <Card sx={{ mt: 4, p: 2 }}> 
              <Typography variant="h4" component="div">
                User Leaderboard
                </Typography>
                <Typography variant="body2" component="div">
                Statistics for all users to compare. Try and beat your friends!
                </Typography>
                <FaceOffDataTable endpoint={`${process.env.REACT_APP_SERVER_URL}/getProfileLeaderboard`} headCells={headCells} defaultSortField={'score'} defaultSortOrder={"desc"}/>
              
              </Card>
            )}
          </Box>
        </Box>
                

        </Container>
    );
};

export default Profile;

// src/routes.js
const routes = [
  { path: '/Logout', element: 'Logout', addToSiteMap: true },
  { path: '/Profile', element: 'Profile', addToSiteMap: true },
  { path: '/Login', element: 'Login', addToSiteMap: true },
  { path: '/', element: 'HomePage', addToSiteMap: true },
  { path: '/TierList', element: 'TierListPage', addToSiteMap: true },
  { path: '/MyCompetitors', element: 'MyCompetitors', addToSiteMap: true },
  { path: '/competitor/:corelation_id', element: 'CompetitorPage', addToSiteMap: false },
  { path: '/criterion/:criterionId', element: 'CriterionPage', addToSiteMap: false },
  { path: '/FaceOff', element: 'FaceOffPage', addToSiteMap: true },
  { path: '/Leaderboards', element: 'LeaderboardsPage', addToSiteMap: true },
  { path: '/Leaderboard', element: 'LeaderboardPage', addToSiteMap: false },
  { path: '/About', element: 'AboutPage', addToSiteMap: true },
  { path: '*', element: 'NotFoundPage', addToSiteMap: true }
];

module.exports = routes; // Use CommonJS export

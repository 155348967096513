import React, { useState, useRef, useEffect } from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import CriterionTag from './CriterionTag';

const CriterionCard = ({ title, name, description, avatar_url, criterionStyles, showTag = true, showDescription = true }) => {
  title = title || name;

  // State to toggle full description visibility
  const [isSmallWidth, setIsSmallWidth] = useState(false); // Track whether width is small
  const [showImage, setShowImage] = useState(true); // Track whether to show the image

  // Ref for the card container
  const cardRef = useRef(null);

  // ResizeObserver to monitor width changes
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const width = entry.contentRect.width;
        setIsSmallWidth(width < 400);
      }
    });

    // Observe the card container
    if (cardRef.current) {
      resizeObserver.observe(cardRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (cardRef.current) {
        resizeObserver.unobserve(cardRef.current);
      }
    };
  }, []);

  // Handle image error to hide image
  const handleImageError = () => {
    setShowImage(false); // Hide the image if it fails to load
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        mb: 4
      }}
      ref={cardRef}
    >
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          mb: 2,
          maxWidth: '100%',
          margin: 'auto',
          flexDirection: isSmallWidth ? 'column' : 'row',
        }}
      >
        {/* Conditionally render the image only if it loads successfully */}
        {showImage && avatar_url && (
          <CardMedia
            component="img"
            sx={{ 
              width: isSmallWidth ? "100%" : "25%",
              height: "auto",
              mr: isSmallWidth ? 0 : 2,
              mb: isSmallWidth ? 2 : 0,
              borderRadius: '16px',
              border: '2px solid #ddd',
            }}
            image={`${process.env.REACT_APP_SERVER_URL}${avatar_url}`}
            alt={name}
            onError={handleImageError} // Hide image on error
          />
        )}

        {/* Right side (or below on small width): Name, CriterionTag, and Description */}
        <CardContent sx={{ flex: '1 1 auto' }}>
          <Typography variant="h5" component="div" gutterBottom>
            {title}
          </Typography>

          {/* CriterionTag component */}
          {showTag && (
            <CriterionTag criterion={name} styles={criterionStyles} tooltipText={description} />
          )}

          {showDescription && (
            <Typography sx={{ whiteSpace: 'pre-line' }} variant="body2" color="textSecondary">
              {description || "No description has been added at this time."}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CriterionCard;

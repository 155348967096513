import React, { useState } from 'react';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Typography,
  TextField,
  Box,
  InputAdornment,
  Tooltip
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import ImageWithPlaceholderOrEmoji from './ImageOrPlaceholder';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

export default function DataImageList({ itemData, linkTo = "/competitor/", itemDescriptor = "Steam Games", metricDescriptor = "minutes played.", showSearch = true }) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const [searchQuery, setSearchQuery] = useState(''); // State to hold the search input

  const getCols = () => {
    if (isXs) return 3;
    if (isSm && !isMd) return 4;
    if (isMd && !isLg) return 5;
    if (isLg) return 6;
    return 2;
  };

  // Filter itemData based on searchQuery
  const filteredData = itemData.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box>
      {showSearch && (
        <TextField
          
          label={`Search for your ${itemDescriptor}`}
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} // Update the search query
          sx={{ m: 2, width:"80%" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}

      {/* Image list */}
      <ImageList cols={getCols()} gap={4} sx={{ mt: 0, mb: 0 }}>
        {Array.isArray(filteredData) && filteredData.length > 0 ? (
          filteredData.map((item) => (

            <ImageListItem key={item.corelation_id}>
             <Tooltip title={item.name} arrow>
              {/* Use react-router-dom Link for navigation */}
              <Link to={`/${linkTo}${item.corelation_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Paper sx={{ p: 0.5, borderRadius: '8px', m: 0.5 }}>
                  <ImageWithPlaceholderOrEmoji
                    src={item.large_image_url}
                    alt={item.name}
                    emoji={item.criterion_emoji} // Pass in emoji if available
                    styles={{
                      transition_1_color: item.transition_color_1 || '#4a4a4a',
                      transition_2_color: item.transition_color_2 || '#141414',
                      font_color: item.font_color || '#FFFFFF'
                    }}
                  />
                  <ImageListItemBar
                    title={
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: 'normal',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical'
                        }}
                      >
                        {item.name}
                      </Typography>
                    }
                    subtitle={<span>{item.relevancy_metric} {metricDescriptor}</span>}
                    position="below"
                  />
                </Paper>
              </Link>
              </Tooltip>
            </ImageListItem>

          ))
        ) : (
          <Typography variant="body1">No {itemDescriptor} found.</Typography>
        )}
      </ImageList>
    </Box>
  );
}

import React from 'react';
import { Typography} from '@mui/material';

function Login({msg}) {
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleLogin = () => {
    const url = `${SERVER_URL}/auth/steam?returnTo=${encodeURIComponent(window.location.href)}`;
    console.log('redirect:',url);
    return url;
  };

  
  return (
    <div>
      <Typography variant="body2">{msg || "You  must sign in to view this data"}</Typography>
      <a href={handleLogin()}><img src="https://community.akamai.steamstatic.com/public/images/signinthroughsteam/sits_02.png" alt="Click to login"></img></a>
    </div>
  );
}

export default Login;

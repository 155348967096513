import * as React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel, Typography, TextField,InputAdornment } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Search } from '@mui/icons-material';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props) {
    const { order, orderBy, headCells, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            backgroundColor: 'black',
                            color: 'white',
                            '& .MuiTableSortLabel-root': { color: 'white' },
                            '& .MuiTableSortLabel-root.Mui-active': { color: 'white' },
                            '& .MuiTableSortLabel-root:hover': { color: 'lightgray' },
                            '& .MuiTableSortLabel-icon': { color: 'white !important' },
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    headCells: PropTypes.array.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
};

export default function EnhancedTable({ rows, headCells, defaultSortField = headCells[0].id, defaultSortOrder = 'asc', enableSearch = true }) {
    const [order, setOrder] = React.useState(defaultSortOrder);
    const [orderBy, setOrderBy] = React.useState(defaultSortField);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = React.useState('');

    // Filter rows based on search term across all columns
    const filteredRows = React.useMemo(() => {
        if (!searchTerm) return rows;
        return rows.filter((row) =>
            headCells.some((headCell) => {
                const cellValue = row[headCell.id];
                return cellValue && cellValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
            })
        );
    }, [rows, searchTerm, headCells]);

    // Sort filtered rows
    const sortedRows = React.useMemo(() => [...filteredRows].sort(getComparator(order, orderBy)), [filteredRows, order, orderBy]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(0); // Reset page to 0 on new search
    };

    const rowCount = filteredRows.length;
    const emptyRows = Math.max(0, (1 + page) * rowsPerPage - rowCount);
    const visibleRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box sx={{ width: '100%' }}>
            {enableSearch && (
                <TextField
                    variant="outlined"
                    label="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    fullWidth
                    margin="normal"
                    sx={{ m: 2, width:"80%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                />
            )}
            <TableContainer>
                <Table sx={{ minWidth: 300 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        headCells={headCells}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {visibleRows.length > 0 ? (
                            visibleRows.map((row, index) => (
                                <TableRow hover tabIndex={-1} key={index}>
                                    {headCells.map((headCell) => (
                                        <TableCell key={headCell.id} align={headCell.numeric ? 'right' : 'left'}>
                                            {headCell.id === 'faceoff' ? (
                                                <Link to={`/faceoff?primary_opponent_id=${row[headCell.competitor]}&criterion_name=${row[headCell.criterion]}&mode=primary`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                    Rank
                                                </Link>
                                            ) : headCell.isImage && row[headCell.id] ? (
                                                <img src={row[headCell.id]} alt={row.name} style={{ width: 'auto', maxHeight: '50px', borderRadius: '8px' }} />
                                            ) : headCell.page && headCell.link_data && row[headCell.link_data] ? (
                                                <Link to={`/${headCell.page}${row[headCell.link_data]}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                    {row[headCell.id] !== undefined && row[headCell.id] !== null ? row[headCell.id] : '—'}
                                                </Link>
                                            ) : typeof row[headCell.id] === 'boolean' ? (
                                                row[headCell.id] ? 'Yes' : 'No'
                                            ) : (
                                                row[headCell.id] !== undefined && row[headCell.id] !== null ? row[headCell.id] : '—'
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={headCells.length}>
                                    <Typography align="center" variant="body1">
                                        No results found.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={headCells.length} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rowCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}

EnhancedTable.propTypes = {
    rows: PropTypes.array.isRequired,
    headCells: PropTypes.array.isRequired,
    defaultSortField: PropTypes.string,
    defaultSortOrder: PropTypes.string,
    enableSearch: PropTypes.bool, // Prop to toggle search
};

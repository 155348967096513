import { Box } from '@mui/material';
import React, { useState } from 'react';
// Image component to handle broken image with placeholder fallback or gradient with emoji
const ImageWithPlaceholderOrEmoji = ({ src, alt, emoji, styles}) => {
    const [imageSrc, setImageSrc] = useState(src || '');
    // Render gradient background with emoji if no image
    const gradientStyle = {
      width: '95%', // Set a relative size for both width and height
      paddingTop: '95%', // This maintains the aspect ratio to make the box a square
      position: 'relative', // To position inner elements inside the box
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
      background: `radial-gradient(circle, ${styles.transition_1_color} 20%, ${styles.transition_2_color} 80%)`,
      color: styles.font_color,
      fontSize: '3rem', // Enlarge the emoji
      textAlign: 'center',
      margin: 'auto',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    };

    const innerStyle = {
      position: 'absolute', // Absolute position inside the container
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const handleImageError = () => {
      console.info("image not found");
      setImageSrc(''); // Set to an empty string to trigger emoji fallback
    };

    try {
    if (imageSrc) {
      return (
        <img
          src={imageSrc}
          alt={alt}
          loading="lazy"
          style={{ width: '95%', borderRadius: '8px' }}
          onError={handleImageError}
        />
      );
    }
  } catch (e) {
    return (
      <Box sx={gradientStyle}>
        <Box sx={innerStyle}>
          {emoji || '🏆'} {/* Use a default trophy emoji if none is provided */}
        </Box>
      </Box>
    );
  
  }

  return (
    <Box sx={gradientStyle}>
      <Box sx={innerStyle}>
        {emoji || '🏆'} {/* Use a default trophy emoji if none is provided */}
      </Box>
    </Box>
  );

  };

  export default ImageWithPlaceholderOrEmoji;
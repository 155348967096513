import FaceOffImageList from '../components/FaceOffImageList';
import { Card, Container, Typography } from '@mui/material'; // Import CircularProgress for loading indicator and Alert for error handling
import React from 'react';


const MyCompetitors = () => {


    return (
        <Container>
            <Card sx={{  p:2}}>
            <Typography variant="h3" component="div">
                My {process.env.REACT_APP_COMPETITOR_DESCRIPTION || 'Competitors'}
            </Typography>
            <Typography variant="body2" component="div">
                These are the {process.env.REACT_APP_COMPETITOR_DESCRIPTION || 'Competitors'} linked with your profile. You cannot rank any that are not linked to your account, and you cannot manually link them from this site.
            </Typography>
            </Card>

            <Card sx={{mt:4, width:"100%"}}>
                <FaceOffImageList dataSource={`${process.env.REACT_APP_SERVER_URL}/GetMyCompetitors`}/>
            </Card>

        </Container>
    );
};

export default MyCompetitors;

import React from 'react';
import { Container,  Typography, Box } from '@mui/material';
import './TierList.css'; // Keep the CSS file for custom styles
import FaceOffImageList from './FaceOffImageList';

const tiers = ['S', 'A', 'B', 'C', 'D', 'F'];

const TierList = ({leaderboardId = 318}) => {
  return (
    <Container maxWidth="x2" className="tier-list">
  {tiers.map((tier, index) => (
    <Box
      key={tier}
      sx={{
        backgroundColor: index % 2 !== 0 ? '#f5f5f5' : 'transparent', // Apply light gray background to odd-indexed boxes
        borderRadius: index % 2 !== 0 ? '8px' : '0px' // Rounded corners for odd-indexed boxes
      }}
    >
      <Box
        className={`tier tier-${tier}`}
        sx={{ display: 'flex', alignItems: 'center', m: 2 }}
      >
        <Typography
          className="tier-header"
          variant="h2"
          component="div"
          sx={{
            backgroundColor: '#f5f5f5',
            padding: '15px',
            textAlign: 'center',
            mr: 4,
          }}
        >
          {tier}
        </Typography>
        <Box
          className="tier-content"
          sx={{
            display: 'flex',
            overflowX: 'auto',
            flexWrap: 'nowrap',
          }}
        >
          <FaceOffImageList
            dataSource={`${process.env.REACT_APP_SERVER_URL}/GetCompetitorRankings?leaderboard_id=${leaderboardId}&tier=${tier}`}
            showSearch={false}
            metricDescriptor="elo"
          />
        </Box>
      </Box>
    </Box>
  ))}
</Container>

  );
};

export default TierList;

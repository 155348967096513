import React from 'react';
import EnhancedTable from './FaceOffTable';

const headCells = [
    { id: 'relevance_rank', label: 'Rank', numeric: true },
    { id: 'competitor_name', label: 'Competitor ID', numeric: false, page:'competitor/', link_data:'competitor' },
    { id: 'competitor_relevance_score', label: 'Play Time Rank (Top %)', numeric: true },
    { id: 'criterion_relevance_score', label: 'Criterion Relevance (Top %)', numeric: true },
    { id: 'elo_difference_score', label: 'ELO Difference', numeric: true },
    { id: 'previous_ranked_count_score', label: "Times you'ved recently ranked", numeric: true },
    { id: 'relevance_total_score', label: 'Total Relevancy Score', numeric: true },
    { id: 'on_cooldown', label: 'Recently Ranked', numeric: false },
    { id: 'exclude', label: 'Exclude', numeric: false }
  ];


const ChallengerMatchesTable = ({ rows }) => {
  return (
      <EnhancedTable rows={rows} headCells={headCells} />
  );
};

export default ChallengerMatchesTable;

import React from 'react';
import { Container, Typography,Card } from '@mui/material';
import FaceOffPage from './FaceOffPage';

const NotFoundPage = () => {
    return (
        <Container>
            <Card sx={{m:3, p:2}}>
            <Typography variant="h1" component="h2" gutterBottom>
                404
            </Typography>
            <Typography variant="h5" component="h5" gutterBottom>
                Page Not Found
            </Typography>
            <Typography>
                The page you are looking for does not exist or has been moved. Rank some games instead.
            </Typography>
            </Card>
            <FaceOffPage/>

        </Container>
    );
};

export default NotFoundPage;

import DataImageList from './DataImageList';
import { CircularProgress, Box, Alert, Container } from '@mui/material'; // Import CircularProgress for loading indicator and Alert for error handling
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Login from '../pages/Login';

const FaceOffImageList = ({
  dataSource = `${process.env.REACT_APP_SERVER_URL}/GetMyCompetitors`,
  linkTo = "competitor/",
  itemDescriptor = "Steam Games",
  metricDescriptor = "minutes played",
  showSearch = true,
}) => {
  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [success, setSuccess] = useState(false); // Add success state
  const [error, setError] = useState(''); // Add error state
  const [unauthorized, setUnauthorized] = useState(false); // Add unauthorized state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(dataSource, { withCredentials: true });
        if (response.data && response.data.length > 0) {
          setItemData(response.data);
          setSuccess(true); // Set success to true if data is successfully fetched
          setError(''); // Clear any previous errors
        } else {
          setSuccess(false); // No data found
          setError('No results.');
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          setUnauthorized(true); // Set unauthorized if a 401 error occurs
        } else {
          console.error('Error fetching data:', err);
          setError('Server error'); // General error message for non-401 errors
        }
        setSuccess(false); // Set success to false if there was an error
      } finally {
        setLoading(false); // Set loading to false after the request is done
      }
    };
    fetchData();
  }, [dataSource]);

  return (
    <Container>
      {loading ? (
        // Show loading indicator while loading is true
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : success ? (
        // Once loading is done and data is fetched successfully, show the data
        <DataImageList
          itemData={itemData}
          linkTo={linkTo}
          itemDescriptor={itemDescriptor}
          metricDescriptor={metricDescriptor}
          showSearch={showSearch}
          sx={{mt: 2}}
        />
      ) : unauthorized ? (
        // Show login component if a 401 Unauthorized error occurs
        <Login msg="You must sign in to view the data." />
      ) : error === 'No results.' ? (
        // Show info message when no data is found
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml:2}}>
          <Alert severity="info">{error}</Alert>
        </Box>
      ) : (
        // Show general error message for all other errors
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml:2}}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
    </Container>
  );
};

export default FaceOffImageList;

import React from 'react';
import { Card, Typography, Box, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { Link } from 'react-router-dom';

export default function WelcomeCard({ user}) {
  return (
    <Card sx={{ padding: 3, mt: 2, textAlign: 'center' }}>
      <Typography variant="h4">Welcome, {user.displayName}</Typography>
      <Box component="img" src={user.avatar} alt="profile" sx={{ width: 100, borderRadius: '50%', my: 2 }} />
      <Typography variant="body1">Steam ID: {user.id}</Typography>

      {/* Competitor Sync Status */}
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        {user.hasCompetitorsSynced ? (
          <Tooltip title={`Last synced at ${new Date(user.competitorLastSyncedAt).toLocaleString()}`}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <CheckCircleIcon color="success" sx={{ mr: 1 }} />
              <Typography>Your games can be imported from your Steam Library.</Typography>
            </Box>
          </Tooltip>
        ) : user.competitorLastSyncedAt ? (
          <Tooltip title={`Last synced at ${new Date(user.competitorLastSyncedAt).toLocaleString()}`}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <WarningIcon color="warning" sx={{ mr: 1 }} />
              <Typography>Your games library has not been updated since {new Date(user.competitorLastSyncedAt).toLocaleString()}.</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Tooltip title="Your are unable to rate games. Please review your privacy settings.">
            <Box display="flex" alignItems="center" justifyContent="center">
              <WarningIcon color="error" sx={{ mr: 1 }} />
              <Typography>Due to your privacy settings, your games cannot be synced from your Steam Library.</Typography>
            </Box>
          </Tooltip>
        )}
      </Box>

      {/* Playtime Sync Status */}
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        {user.hasRelevanceSynced ? (
          <Tooltip title={`Relevance synced at ${new Date(user.relevanceLastSyncedAt).toLocaleString()}`}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <CheckCircleIcon color="success" sx={{ mr: 1 }} />
              <Typography>Your playtime can be imported.</Typography>
            </Box>
          </Tooltip>
        ) : user.relevanceLastSyncedAt ? (
          <Tooltip title={`Relevance synced at ${new Date(user.relevanceLastSyncedAt).toLocaleString()}`}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <WarningIcon color="warning" sx={{ mr: 1 }} />
              <Typography>Your game playtime is not up to date.</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Tooltip title="Competitor relevance algorithm will be sub-optimal.">
            <Box display="flex" alignItems="center" justifyContent="center">
              <WarningIcon color="error" sx={{ mr: 1 }} />
              <Typography>Due to your privacy settings, game playtime cannot be synced.</Typography>
            </Box>
          </Tooltip>
        )}
        </Box>


      <Typography sx={{ mt: 2 }}>  {/* You can also use style={{ marginBottom: '16px' }} */}
      Your games and playtime will be synced momentarily after login. It may take a moment for new games and criterion to be created in the database.
      </Typography>
      <Typography>
      Access will depend on your <Link to={`${user.sourceProfileURL}edit/settings`} style={{ color: 'inherit', textDecoration: 'underline' }}>Steam profile privacy settings</Link>, which can be reviewed at any time.
      </Typography>

    </Card>
  );
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Container, Typography, Box, Switch, FormControlLabel, Alert, CircularProgress, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CriterionCard from './CriterionCard';

const AITesterForm = ({criterion,competitorDescriptor,criterionDescriptor}) => {

  const [formValues, setFormValues] = useState({
    competitorDescriptor: competitorDescriptor || 'Competitor', // Default value
    criterionDescriptor: criterionDescriptor || 'Criterion', // Default value
    criterionName: criterion || "" // Start with an empty value
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(''); // State to show success message
  const [styles, setStyles] = useState('');
  const [criterionDescription, setCriterionDescription] = useState('');
  const [leaderboardDescription, setLeaderboardDescription] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [generateImage, setGenerateImage] = useState(false); // State for switch
  const [submitted, setSubmitted] = useState(false); // State to track successful submission
  const [requestId, setRequestId] = useState(null);

  // Use useEffect to set criterionName based on the URL param after the component mounts
  useEffect(() => {
    console.log("URL param criterion:", criterion); // Log the criterion param
    if (criterion) {
      setFormValues((prevValues) => ({
        ...prevValues

      }));
    }
  }, [criterion]); // Only run this effect when the criterion param changes

  // Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  // Handle switch change
  const handleSwitchChange = (event) => {
    setGenerateImage(event.target.checked);
  };

  // Validate form fields
  const validateForm = () => {
    const { competitorDescriptor, criterionDescriptor, criterionName } = formValues;
    if (!competitorDescriptor || !criterionDescriptor || !criterionName) {
      setError('All fields are required.');
      return false;
    }
    return true;
  };

  // Handle form submission to generate criterion details
  const handleSubmit = async (event) => {
    setError('');
    setSuccess('');
    event.preventDefault();
    if (!validateForm()) return;

    const { competitorDescriptor, criterionDescriptor, criterionName } = formValues;
    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/generate-criterion-content`, { 
        criterionName, 
        generateImage,
        competitorDescriptor, 
        criterionDescriptor 
      });
      const stylesObj = JSON.parse(response.data.styles);
      setStyles(stylesObj);
      setCriterionDescription(response.data.criterion_description);
      setLeaderboardDescription(response.data.leaderboard_description);
      setImageURL(response.data.imageURL);
      setRequestId(response.data.request_id);
      setSubmitted(true); // Mark as successfully submitted
    } catch (err) {
      console.error('Error fetching image and description:', err);
      setError('Failed to fetch image and description. Please try again.');
    }
    setLoading(false);
  };

  // Handle applying generated data and reload the page
  const handleApplyGeneratedData = async () => {
    const generatedCriterion = { 
      Name: formValues.criterionName, 
      emoji: styles.emoji,
      description: criterionDescription,
      leaderboard_description: leaderboardDescription,
      ...(imageURL ? { avatar_url: imageURL } : {}),
      transition_color_1: styles.transition_1_color,
      transition_color_2: styles.transition_2_color,
      font_color: styles.font_color,
      genai_request: requestId
  };
  

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/save-criterion`, generatedCriterion);
      setSuccess('Generated data applied successfully!');
      setError(''); // Clear error

      // Reload the page after successful submission
      window.location.reload(); 
    } catch (err) {
      console.error('Error applying generated data:', err);
      setError('Failed to apply generated data.');
      setSuccess(''); // Clear success message
    }
  };

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 8
          }}
        >
          <Typography variant="h4" component="h1">
            Criterion GenAI Testing Tool
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {success}
            </Alert>
          )}
          <Box
            component="form"
            noValidate
            sx={{ mt: 3 }}
            onSubmit={handleSubmit}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="competitorDescriptor"
              label="Competitor Descriptor"
              name="competitorDescriptor"
              value={formValues.competitorDescriptor}
              onChange={handleChange}
              autoComplete="competitorDescriptor"
              autoFocus
              disabled={loading}
              error={!formValues.competitorDescriptor && Boolean(error)}
              helperText={!formValues.competitorDescriptor && error}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="criterionDescriptor"
              label="Criterion Descriptor"
              name="criterionDescriptor"
              value={formValues.criterionDescriptor}
              onChange={handleChange}
              autoComplete="criterionDescriptor"
              disabled={loading}
              error={!formValues.criterionDescriptor && Boolean(error)}
              helperText={!formValues.criterionDescriptor && error}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="criterionName"
              label="Criterion Name"
              name="criterionName"
              value={formValues.criterionName}
              onChange={handleChange}
              autoComplete="criterionName"
              disabled={true}
              error={!formValues.criterionName && Boolean(error)}
              helperText={!formValues.criterionName && error}
            />
            <Box sx={{ mt: 2, mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={generateImage}
                    onChange={handleSwitchChange}
                    color="primary"
                    disabled={loading}
                  />
                }
                label="Generate Image"
              />
            </Box>
            <LoadingButton
              onClick={handleSubmit}
              endIcon={<AutoAwesomeIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
              disabled={loading}
            >
              Generate Details
            </LoadingButton>
          </Box>
        </Box>
      </Container>

      <Box sx={{ mt: 4, textAlign: 'center' }}>
        {loading ? (
          <CircularProgress />
        ) : submitted ? (
          <Container>
            {/* Show the 'Apply Generated Data' button only if formValues.criterionName exists */}
            {formValues.criterionName && (
              <Button
                onClick={handleApplyGeneratedData}
                variant="contained"
                sx={{ m: 2 }}
              >
                Apply Changes
              </Button>
            )}
            <CriterionCard
              name={formValues.criterionName}
              description={criterionDescription + "\n\n" + leaderboardDescription}
              avatar_url={imageURL}
              criterionStyles={styles}
              tooltipText="This is a tooltip for the criterion"
            />
          </Container>
        ) : null}
      </Box>
    </div>
  );
};

export default AITesterForm;
